import React from 'react'
import {
  BackgroundContainer,
  FirstSectionWrapper,
  FooterWrapper
} from './carrierThankYou.style'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import Button from '../../components/CustomButton'

const CarrierThankYou = () => {
  const Data = useStaticQuery(graphql`
    {
        allStrapiCarrierThankYou {
            edges {
              node {
                id
                data {
                  carrierThankYouPageInfo {
                    title
                    description
                  }
                }
                CarrierThankYouBackground {
                    childImageSharp {
                        fluid(quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                }
              }
            }
          }
        }
  `)
  const {
    data: { carrierThankYouPageInfo: data},
    CarrierThankYouBackground
  } = Data.allStrapiCarrierThankYou.edges[0].node

  return (
    <section id="CRHeader">
      <BackgroundContainer fixed={CarrierThankYouBackground.childImageSharp.fluid}>
        <FirstSectionWrapper>
          <h1>{data.title}</h1>
          <p>{data.description}</p>
        </FirstSectionWrapper>
      </BackgroundContainer>
      <FooterWrapper>
        <Button
          className="go-back-button"
          text="Go Back"
          onClick={() => navigate('/')}
        />
      </FooterWrapper>
    </section>
  )
}

export default CarrierThankYou
