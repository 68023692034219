//  libraries
import React from 'react'

//  containers
import ThankYouContainer from '../containers/CarrierThankYou'
//  context

//  components
import Layout from 'components/layout'
import SEO from 'components/seo'

const CarrierThankYou = () => {
  return (
    <Layout>
      <SEO title="Thank you" />
      <ThankYouContainer />
    </Layout>
  )
}

export default CarrierThankYou
